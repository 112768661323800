<template>
    <v-col v-bind="$attrs">
        <div :class="{ 'grey lighten-4 grey-8f--text': isClosed || isApproved, 'green lighten-1 white--text': !isClosed && !isApproved }" class="min-h-46px min-h-md-60px d-flex align-center justify-center px-4 py-10">
            {{ isClosed || isApproved ? "마감" : "승인대기" }}
        </div>
    </v-col>
</template>

<script>
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";
import dayjs from "dayjs";
export default {
    props: {
        item: { type: Object, default: () => ({}) },
        date: { type: String },
        time: { type: Number },
    },
    computed: {
        scheduleOnCloud() {
            return this.item.schedulesOnCloud.find((schedule) => schedule.time == this.time);
        },
        isClosed() {
            return dayjs(this.date)
                .set("hour", this.time)
                .isBefore(dayjs());
        },
        isApproved() {
            return this.scheduleOnCloud?.state == RENTAL_SCHEDULE_STATES.APPROVED.value;
        },
    },
};
</script>
