var render = function render(){
  var _vm$program;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "참여프로그램",
            "src": "/images/sub/sv-programs.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('program-view-card', _vm._b({}, 'program-view-card', {
    program: _vm.program
  }, false)), _c('v-divider', {
    staticClass: "border-2 primary mt-40 mt-md-60 mt-lg-80"
  }), _c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('div', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : _vm$program.detail)
    }
  })]), _c('v-divider'), _c('div', {
    staticClass: "mt-12 mt-md-22"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-140px",
    attrs: {
      "outlined": "",
      "color": "grey-d6"
    },
    on: {
      "click": _vm.goList
    }
  }, [_vm._v("목록")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }