<template>
    <v-date-picker v-model="date" no-title color="primary" v-bind="{ ...$attrs, dayFormat, allowedDates }" @update:picker-date="changeMonth" />
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: String, default: Date.now().toDate() },
    },
    data: () => ({
        date: Date.now().toDate(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        date() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.date = this.value || Date.now().toDate();
        },

        emit() {
            this.$emit("input", this.date);
        },

        changeMonth(date) {
            let month = date.split("-")[1];
            let today = dayjs().format("YYYY-MM-DD");
            if (month == today.split("-")[1]) {
                this.date = today;
            } else {
                this.date = dayjs(date)
                    .startOf("month")
                    .format("YYYY-MM-DD");
            }
        },

        dayFormat(date) {
            return dayjs(date).get("date");
        },

        allowedDates(date) {
            return (
                !dayjs().isAfter(date, "date") &&
                dayjs()
                    .add(60, "days")
                    .isAfter(date, "date")
            );
        },
    },
};
</script>

<style></style>
