<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="참여프로그램" src="/images/sub/sv-programs.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <program-navigation-row />
        </template>

        <v-container>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-card>
                        <div class="pa-20 pa-md-30">
                            <program-schedule-calendar v-bind="{ programs }" no-title />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <program-schedule-list v-bind="{ programs }" />
                </v-col>
            </v-row>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ProgramNavigationRow from "@/components/client/program/program-navigation-row.vue";
import ProgramScheduleCalendar from "@/components/client/program/program-schedule-calendar.vue";
import ProgramScheduleList from "@/components/client/program/program-schedule-list.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        ProgramNavigationRow,
        ProgramScheduleCalendar,
        ProgramScheduleList,
    },
    data() {
        return {
            date: this.$route.query.date || Date.now().toDate(),
            programs: [],

            limit: 10,
            summary: { totalCount: 0 },
        };
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            return {
                dateFrom: dayjs(this.date)
                    .startOf("month")
                    .toISOString(),
                dateTo: dayjs(this.date)
                    .endOf("month")
                    .toISOString(),
            };
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        "$route.path"() {
            this.reroute();
        },
        "$route.query.date"(date = Date.now().toDate()) {
            this.date = date;
        },
        params() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            let path = "/program/schedule";
            if (this.$route.path != path) this.$router.replace({ path });
        },
        async search() {
            let { headers, params } = this;
            let { summary, programs } = await api.v1.programs.gets({ headers, params });
            this.summary = summary;
            this.programs = programs;
        },
    },
};
</script>
