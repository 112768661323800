<template>
    <v-tabs hide-slider show-arrows>
        <slot />
    </v-tabs>
</template>

<script>
export default {
    props: {
    },
};
</script>

<style scoped>
>>>.v-tabs-bar{
    height: 40px;
}
>>>.v-tab{
    padding:0 4px;
}
>>>.v-tab:first-child{
    padding-left:0;
}
>>>.v-tab .v-btn{
    min-width: 100px;
}
>>>.v-tab--active .v-btn{
    border-color: var(--v-primary-base);
    background-color: var(--v-primary-base);
}
>>>.v-tab--active .v-btn .v-btn__content{
    color:#fff !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    >>>.v-tabs-bar{
        height: 50px;
    }
    >>>.v-tab{
        padding:0 8px;
    }
    >>>.v-tab .v-btn{
        min-width: 180px;
    }
}
@media (min-width:1024px){
    >>>.v-tabs-bar{
        height: 54px;
    }
}
@media (min-width:1200px){
}
</style>
