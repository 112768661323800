var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "공간대여",
            "src": "/images/sub/sv-rental.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('rental-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tab-wrap"
  }, [_c('rental-space-navigation')], 1), _vm._l(_vm.spaces, function (item, index) {
    var _item$thumb, _item$area, _item$area$square, _item$area$square$for, _item$area2, _item$area3;
    return _c('v-card', {
      key: item === null || item === void 0 ? void 0 : item._id,
      class: index != 0 ? 'mt-40 mt-md-50 mt-lg-60' : ''
    }, [_c('div', {
      staticClass: "pa-30 pa-md-40 pa-lg-60"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-img', {
      staticClass: "rounded-lg ma-auto",
      attrs: {
        "width": _vm.$vuetify.breakpoint.xs ? '100%' : 350,
        "aspect-ratio": 1 / 1,
        "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "z-index": "0",
              "opacity": ".1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1), _c('v-col', [_c('div', {
      staticClass: "mt-20 mt-md-30 ml-md-20 ml-lg-40 ml-xl-72"
    }, [_c('div', {
      staticClass: "tit-wrap mb-0"
    }, [_c('h3', {
      staticClass: "tit tit--xs subtit"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.branch))]), _c('h2', {
      staticClass: "tit"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))])]), _c('v-divider', {
      staticClass: "my-14 my-md-24"
    }), _c('div', {
      staticClass: "page-text"
    }, [_c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-lg-100px font-weight-bold grey-1e--text"
    }, [_vm._v("면적(㎡)")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$area = item.area) === null || _item$area === void 0 ? void 0 : (_item$area$square = _item$area.square) === null || _item$area$square === void 0 ? void 0 : (_item$area$square$for = _item$area$square.format) === null || _item$area$square$for === void 0 ? void 0 : _item$area$square$for.call(_item$area$square)))])], 1), _c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-lg-100px font-weight-bold grey-1e--text"
    }, [_vm._v("넓이(m)")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$area2 = item.area) === null || _item$area2 === void 0 ? void 0 : _item$area2.width) + " x " + _vm._s(item === null || item === void 0 ? void 0 : (_item$area3 = item.area) === null || _item$area3 === void 0 ? void 0 : _item$area3.height))])], 1), _c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-lg-100px font-weight-bold grey-1e--text"
    }, [_vm._v("수용규모(명)")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.capacity))])], 1), _c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-lg-100px font-weight-bold grey-1e--text"
    }, [_vm._v("대여용도")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.usage))])], 1), _c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-lg-100px font-weight-bold grey-1e--text"
    }, [_vm._v("설비")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', {
      staticStyle: {
        "white-space": "pre-line"
      }
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.facility))])], 1)], 1)], 1)])], 1), _c('div', {
      staticClass: "mt-30 mt-md-40 mt-lg-60"
    }, [_c('imgs-overlay-list', {
      attrs: {
        "items": ((item === null || item === void 0 ? void 0 : item.photos) || []).map(function (_ref) {
          var url = _ref.url;
          return url;
        })
      }
    })], 1)], 1)]);
  }), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mx-4",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }