<template>
    <navigation-chips v-bind="{ items }" />
</template>

<script>
import { branches } from "@/assets/variables";

import NavigationChips from "../dumb/navigation-chips.vue";

export default {
    components: {
        NavigationChips,
    },
    computed: {
        items() {
            const query = { ...this.$route.query };
            delete query.branch;

            const items = [{ text: "전체", to: { query } }].concat(branches.map((text) => ({ text, to: { query: { ...this.$route.query, branch: text } } })));
            items.pop();
            return items;
        },
    },
};
</script>

<style scoped>
</style>
