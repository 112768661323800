var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', _vm._b({}, 'v-col', _vm.$attrs, false), [_c('div', {
    staticClass: "min-h-46px min-h-md-60px d-flex align-center justify-center px-4 py-10",
    class: {
      'grey lighten-4 grey-8f--text': _vm.isClosed || _vm.isApproved,
      'green lighten-1 white--text': !_vm.isClosed && !_vm.isApproved
    }
  }, [_vm._v(" " + _vm._s(_vm.isClosed || _vm.isApproved ? "마감" : "승인대기") + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }