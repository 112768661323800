var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "overflow-hidden"
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 px-lg-60 py-lg-40"
  }, [_c('rental-schedule-date-picker', {
    staticClass: "v-date-picker--default",
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center h-100 primary lighten-5 pa-20 pa-md-30 px-lg-50 py-lg-60"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', [_c('div', {
    staticClass: "mb-8 mb-md-10"
  }, [_c('h3', {
    staticClass: "font-size-16 font-size-md-18"
  }, [_vm._v("신청순서")])]), _c('v-card', {
    staticStyle: {
      "background-color": "transparent"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-12 pa-md-18"
  }, [_c('v-row', {
    staticClass: "page-text page-text--sm line-height-13",
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "center",
      "justify-xl": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("날짜, 시간, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v("공간 선택")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-chevron-right")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("행사정보 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v("담당자 정보 입력")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-chevron-right")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("대관 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v("신청하기")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-chevron-right")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("관리자 승인 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v("후 이용")])], 1)], 1)])], 1), _c('div', {
    staticClass: "mt-20 mt-md-34"
  }, [_c('div', {
    staticClass: "mb-8 mb-md-10"
  }, [_c('h3', {
    staticClass: "font-size-16 font-size-md-18"
  }, [_vm._v("유의사항")])]), _c('div', {
    staticClass: "page-text page-text--sm"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("최소 이용 예정 시각 3시간 전까지 예약신청이 가능합니다. ")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("대관 신청은 이용자의 독점을 방지하고자 주 1회 3시간으로 제한하고 있습니다.")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("하다센터 정기 대관 및 기타 자세한 문의는 카카오톡 생활문화센터 하다 채널 또는 담당자 (031-791-5002)을 통해 연락 바랍니다.")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("날다센터 멀티룸의 이용 시간은 6시까지이며 대관 예약 등 문의 사항은 (031-796-2173)을 통해 연락 바랍니다.")])], 1)], 1)])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }