var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs-rounded', {
    attrs: {
      "hide-slider": "",
      "show-arrows": ""
    }
  }, _vm._l(_vm.items, function (_ref) {
    var to = _ref.to,
      text = _ref.text;
    return _c('v-tab', _vm._b({
      key: text,
      attrs: {
        "exact": ""
      }
    }, 'v-tab', {
      to
    }, false), [_c('v-btn', {
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": "grey-d6"
      }
    }, [_vm._v(_vm._s(text))])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }