<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="공간대여" src="/images/sub/sv-rental.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <rental-navigation-row />
        </template>

        <v-container>
            <div class="tab-wrap mb-40 mb-md-60">
                <rental-space-navigation />
            </div>
        </v-container>

        <page-section class="page-section--small page-section--first">
            <div class="tit-wrap tit-wrap--dot"><h2 class="tit">날짜 선택하기</h2></div>
            <rental-action-date-picker v-bind="{ loading }" />
        </page-section>

        <page-section class="page-section--small">
            <div class="mb-24 mb-lg-28">
                <v-row align="end" justify="space-between" class="row--small">
                    <v-col cols="12" sm="auto">
                        <div class="tit-wrap tit-wrap--dot mb-0"><h2 class="tit">대관 선택하기</h2></div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-row class="mx-n6 my-n4 ma-md-n10" justify="end" justify-sm="start">
                            <v-col cols="auto" class="px-6 py-4 pa-md-10">
                                <div class="d-flex align-center">
                                    <v-avatar :size="$vuetify.breakpoint.xs ? 14 : 20" color="grey-8f" />
                                    <p class="page-text ml-4 ml-md-6">마감</p>
                                </div>
                            </v-col>
                            <v-col cols="auto" class="px-6 py-4 pa-md-10">
                                <div class="d-flex align-center">
                                    <v-avatar :size="$vuetify.breakpoint.xs ? 14 : 20" color="green lighten-1" />
                                    <p class="page-text ml-4 ml-md-6">승인대기</p>
                                </div>
                            </v-col>
                            <!-- <v-col cols="auto" class="px-6 py-4 pa-md-10">
                                <div class="d-flex align-center">
                                    <v-avatar :size="$vuetify.breakpoint.xs ? 14 : 20" color="primary" />
                                    <p class="page-text ml-4 ml-md-6">예약가능</p>
                                </div>
                            </v-col> -->
                            <v-col cols="auto" class="px-6 py-4 pa-md-10">
                                <div class="d-flex align-center">
                                    <v-simple-checkbox hide-details color="primary" class="mr-n4" />
                                    <p class="page-text">선택가능</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>

            <rental-action-spacetime-table v-model="schedules" v-bind="{ loading, spaces, schedulesOfUser, schedulesOnCloud, limitationMet, limitationMetOnBranches }" />
        </page-section>

        <page-section class="page-section--small">
            <div class="tit-wrap tit-wrap--dot"><h2 class="tit">선택내용 확인</h2></div>
            <rental-action-schedules v-bind="{ spaces, schedules }" />
        </page-section>

        <page-section class="page-section--small">
            <div class="tit-wrap tit-wrap--dot"><h2 class="tit">사용목적</h2></div>
            <v-textarea v-model="usage" outlined no-resize height="180" placeholder="동아리명(해당시), 사용목적, 이용인원, 기자재사용내용(해당시) 입력해주세요." class="rounded" />
        </page-section>

        <page-section class="page-section--small">
            <div class="tit-wrap tit-wrap--dot"><h2 class="tit">하남시도시재생지원센터 공유공간 이용규정 준수 서약서</h2></div>
            <terms-component code="schedule-usage" />
            <div class="text-center mt-16 mt-md-26">
                <v-checkbox v-model="terms" value="schedule-usage" multiple hide-details class="v-checkbox--large d-inline-flex">
                    <template #label>
                        <strong class="font-size-16 font-size-md-18 line-height-12 grey-1e--text"><span class="font-size-20 font-size-md-24 primary--text">하남시도시재생지원센터 공유공간 이용규정 준수 서약서</span> 에 동의합니다</strong>
                    </template>
                </v-checkbox>
            </div>
        </page-section>

        <v-container>
            <!-- 하단버튼 -->
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="6" sm="auto">
                        <v-btn large outlined block color="grey-d6" class="w-md-240px" @click="$router.go(-1)">취소</v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn large block color="primary" class="w-md-240px" @click="save">예약신청</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <rental-limitation-per-week v-model="limitationPerWeekMet" v-bind="{ date, limitations, schedules }" />
        <rental-limitation-per-week-on-branch v-model="limitationPerWeekMetOnBranches" v-bind="{ date, limitations, schedules }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/dumb/page-section.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";
import RentalNavigationRow from "@/components/client/rental/rental-navigation-row.vue";
import RentalSpaceNavigation from "@/components/client/rental/rental-space-navigation.vue";
import RentalActionSchedules from "@/components/client/rental/rental-action-schedules.vue";
import RentalActionDatePicker from "@/components/client/rental/rental-action-date-picker.vue";
import RentalLimitationPerWeek from "@/components/client/rental/rental-limitation-per-week.vue";
import RentalActionSpacetimeTable from "@/components/client/rental/rental-action-spacetime-table.vue";
import RentalLimitationPerWeekOnBranch from "@/components/client/rental/rental-limitation-per-week-on-branch.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        TermsComponent,
        RentalNavigationRow,
        RentalSpaceNavigation,
        RentalActionSchedules,
        RentalActionDatePicker,
        RentalLimitationPerWeek,
        RentalActionSpacetimeTable,
        RentalLimitationPerWeekOnBranch,
    },
    data: () => ({
        loading: false,
        usage: null,
        terms: [],

        spaces: [],

        schedulesOfUser: [],
        schedulesOnCloud: [],

        limitations: [],
        limitationPerWeekMet: false,
        limitationPerWeekMetOnBranches: [],

        schedules: [],
    }),
    computed: {
        branch() {
            let { branch } = this.$route.query;
            return branch;
        },
        date() {
            let { date } = this.$route.query;
            return date;
        },
        hasLoggedIn() {
            return !!this.$store.state.accessToken;
        },
        limitationMet() {
            return this.limitationPerWeekMet;
        },
        limitationMetOnBranches() {
            return this.limitationPerWeekMetOnBranches;
        },
    },
    mounted() {
        this.reroute()
            .then(this.init)
            .then(this.getSchedules);
    },
    watch: {
        branch() {
            this.getSchedules();
        },
        date() {
            this.getSchedules();
        },
    },
    methods: {
        async reroute() {
            const path = "/rental/action";
            if (this.$route.path !== path) this.$router.replace({ path });
        },
        async init() {
            let { spaces } = await api.v1.rentalSpaces.gets({ params: { shows: true } });
            this.spaces = spaces;

            let { limitations } = await api.v1.rentalLimitations.gets({ params: { uses: true } });
            this.limitations = limitations;
        },
        async getSchedules() {
            this.loading = true;
            try {
                let { branch, date } = this;
                let { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;
                let { schedules: schedulesOnCloud } = await api.v1.rentalSchedules.gets({
                    params: { branch, date, state: [APPLIED.value, APPROVED.value] },
                });
                this.schedulesOnCloud = schedulesOnCloud;

                if (this.hasLoggedIn) {
                    let { schedules: schedulesOfUser } = await api.v1.me.rentalSchedules.gets({
                        params: { date, state: [APPLIED.value, APPROVED.value] },
                    });
                    this.schedulesOfUser = schedulesOfUser;
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                if (!this.hasLoggedIn) throw new Error("회원만 사용할 수 있습니다");

                if (this.$store.state.scope.includes("console")) throw new Error("관리자 계정으로는 사용할 수 없는 기능입니다");

                if (this.schedules.length < 1) throw new Error("스케줄을 선택해주세요");
                if (!this.usage) throw new Error("사용목적을 작성해주세요");

                if (["schedule-usage"].some((item) => !this.terms.includes(item))) throw new Error("약관에 동의해주세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;
            let { usage } = this;
            this.schedules = await Promise.all(
                this.schedules.map(async (schedule) => {
                    try {
                        return { ...((await api.v1.me.rentalSchedules.post({ ...schedule, usage }))?.schedule || {}), success: true };
                    } catch (error) {
                        console.error(error);
                        return { ...schedule, success: false };
                    }
                })
            );
            let hasFailedItem = this.schedules.some(({ success }) => !success);
            if (hasFailedItem) {
                alert("대여 예약 신청이 실패하였습니다.\n성공한 예약을 확인후 다시 신청해주세요.");
            } else {
                alert("대여 예약 신청에 성공하였습니다");
            }

            this.$router.push("/mypage/rental-schedules");
        },
    },
};
</script>
