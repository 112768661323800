<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="공간대여" src="/images/sub/sv-rental.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="공간대여" tabActive="예약안내"></client-gnb>
        </template>

        <page-section class="page-section--first">
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">예약 절차</h2>
            </div>
            <v-card color="primary lighten-5" elevation="0">
                <div class="pa-30 px-lg-50 py-lg-60">
                    <v-card :rounded="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '' : 'pill'" color="white" elevation="0">
                        <div class="pa-20 pa-md-10">
                            <v-row align="center" justify="center" justify-md="start">
                                <v-col cols="auto">
                                    <v-responsive :width="$vuetify.breakpoint.xs ? 120 : 156" :aspect-ratio="1/1">
                                        <v-sheet width="100%" height="100%" rounded="circle" class="d-flex align-center justify-center border">
                                            <div>
                                                <v-img contain max-height="60" src="/images/sub/rental/rental-intro-icon.png" />
                                                <div class="font-size-16 font-size-md-18 text-center mt-2 mb-n6">STEP. 1</div>
                                            </div>
                                        </v-sheet>
                                    </v-responsive>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div class="tit-wrap mb-10 mb-md-12">
                                        <h3 class="tit tit--sm">예약 신청</h3>
                                    </div>
                                    <div class="page-text">
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">홈페이지를 통해 예약현황 확인</v-col>
                                        </v-row>
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">예약신청 후 온라인 예약서 작성 (이용 약관 확인 및 행사 목적 필수)</v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div class="pl-md-80 my-14">
                        <v-img contain max-width="18" class="ma-auto ma-md-0" src="/images/sub/rental/rental-intro-arrow.png" />
                    </div>

                    <v-card :rounded="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '' : 'pill'" color="white" elevation="0">
                        <div class="pa-20 pa-md-10">
                            <v-row align="center" justify="center" justify-md="start">
                                <v-col cols="auto">
                                    <v-responsive :width="$vuetify.breakpoint.xs ? 120 : 156" :aspect-ratio="1/1">
                                        <v-sheet width="100%" height="100%" rounded="circle" class="d-flex align-center justify-center border">
                                            <div>
                                                <v-img contain max-height="60" src="/images/sub/rental/rental-intro-icon2.png" />
                                                <div class="font-size-16 font-size-md-18 text-center mt-2 mb-n6">STEP. 2</div>
                                            </div>
                                        </v-sheet>
                                    </v-responsive>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div class="tit-wrap mb-10 mb-md-12">
                                        <h3 class="tit tit--sm">예약 확인</h3>
                                    </div>
                                    <div class="page-text">
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">예약 담당자와 예약 내용 및 장비사용 확인</v-col>
                                        </v-row>
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">행사 개최 등 예약 적 여부 심사 및 대관 승인 (예약 신청일 기준 2.3일 소요)</v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div class="pl-md-80 my-14">
                        <v-img contain max-width="14" class="ma-auto ma-md-0" src="/images/sub/rental/rental-intro-arrow2.png" />
                    </div>

                    <v-card :rounded="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '' : 'pill'" color="white" elevation="0">
                        <div class="pa-20 pa-md-10">
                            <v-row align="center" justify="center" justify-md="start">
                                <v-col cols="auto">
                                    <v-responsive :width="$vuetify.breakpoint.xs ? 120 : 156" :aspect-ratio="1/1">
                                        <v-sheet width="100%" height="100%" rounded="circle" class="d-flex align-center justify-center border">
                                            <div>
                                                <v-img contain max-height="60" src="/images/sub/rental/rental-intro-icon3.png" />
                                                <div class="font-size-16 font-size-md-18 text-center mt-2 mb-n6">STEP. 3</div>
                                            </div>
                                        </v-sheet>
                                    </v-responsive>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div class="tit-wrap mb-10 mb-md-12">
                                        <h3 class="tit tit--sm">대관 이용</h3>
                                    </div>
                                    <div class="page-text">
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">대관 승인 시 통보받은 도어락 비밀번호로 문 개방 및 이용 (다목적홀 제외)</v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div class="pl-md-80 my-14">
                        <v-img contain max-width="14" class="ma-auto ma-md-0" src="/images/sub/rental/rental-intro-arrow2.png" />
                    </div>

                    <v-card :rounded="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '' : 'pill'" color="white" elevation="0">
                        <div class="pa-20 pa-md-10">
                            <v-row align="center" justify="center" justify-md="start">
                                <v-col cols="auto">
                                    <v-responsive :width="$vuetify.breakpoint.xs ? 120 : 156" :aspect-ratio="1/1">
                                        <v-sheet width="100%" height="100%" rounded="circle" class="d-flex align-center justify-center border">
                                            <div>
                                                <v-img contain max-height="60" src="/images/sub/rental/rental-intro-icon4.png" />
                                                <div class="font-size-16 font-size-md-18 text-center mt-2 mb-n6">STEP. 4</div>
                                            </div>
                                        </v-sheet>
                                    </v-responsive>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div class="tit-wrap mb-10 mb-md-12">
                                        <h3 class="tit tit--sm">대관 종료</h3>
                                    </div>
                                    <div class="page-text">
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">대관 종료 후 시설 담당자에게 고지</v-col>
                                        </v-row>
                                        <v-row class="ma-n2 ma-md-n4">
                                            <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                            <v-col class="pa-2 pa-md-4">사용시설 등 확인</v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </page-section>

        <page-section class="page-section--small page-section--last">
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">유의 사항</h2>
            </div>
            <v-divider class="border-2 primary"/>
            <div class="pa-20 pa-md-30 page-text">
                <v-row class="ma-n2 ma-md-n4">
                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                    <v-col class="pa-2 pa-md-4">이용시간은 개인, 단체별 주1회 최대 3시간 까지 가능합니다. (준비 및 철수 시간 포함)</v-col>
                </v-row>
                <v-row class="ma-n2 ma-md-n4 mt-2 mt-md-4">
                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                    <v-col class="pa-2 pa-md-4">사전 통보없이 노쇼(no-show)시 추후 공간 이용에 제한을 받을 수 있습니다.</v-col>
                </v-row>
                <v-row class="ma-n2 ma-md-n4 mt-2 mt-md-4">
                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                    <v-col class="pa-2 pa-md-4">행사장 사전 세팅 필요 시, 담당자와 협의해주시기 바랍니다.</v-col>
                </v-row>
            </div>
            <v-divider />
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>