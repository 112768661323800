<template>
    <div class="sub-tab-wrap">
        <v-container>
            <ul class="tab justify-center">
                <li v-for="{ to, alternate, text } in items" :key="text" :class="{ active: $route.path.includes(to) || $route.path.includes(alternate) }" class="tab__li">
                    <router-link class="tab__btn" :to="to">
                        <span>{{ text }}</span>
                    </router-link>
                </li>
            </ul>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [{ to: "/", text: "Home" }] },
    },
};
</script>

<style scoped>
</style>