<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "../dumb/navigation-row.vue";
const items = [
    { text: "시설소개", to: "/rental/spaces" },
    { text: "예약안내", to: "/rental/intro" },
    { text: "예약하기", to: "/rental/action" },
];
export default {
    components: {
        NavigationRow,
    },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
