var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "공간대여",
            "src": "/images/sub/sv-rental.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('rental-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tab-wrap mb-40 mb-md-60"
  }, [_c('rental-space-navigation')], 1)]), _c('page-section', {
    staticClass: "page-section--small page-section--first"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("날짜 선택하기")])]), _c('rental-action-date-picker', _vm._b({}, 'rental-action-date-picker', {
    loading: _vm.loading
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--small"
  }, [_c('div', {
    staticClass: "mb-24 mb-lg-28"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "end",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot mb-0"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("대관 선택하기")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "mx-n6 my-n4 ma-md-n10",
    attrs: {
      "justify": "end",
      "justify-sm": "start"
    }
  }, [_c('v-col', {
    staticClass: "px-6 py-4 pa-md-10",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.xs ? 14 : 20,
      "color": "grey-8f"
    }
  }), _c('p', {
    staticClass: "page-text ml-4 ml-md-6"
  }, [_vm._v("마감")])], 1)]), _c('v-col', {
    staticClass: "px-6 py-4 pa-md-10",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.xs ? 14 : 20,
      "color": "green lighten-1"
    }
  }), _c('p', {
    staticClass: "page-text ml-4 ml-md-6"
  }, [_vm._v("승인대기")])], 1)]), _c('v-col', {
    staticClass: "px-6 py-4 pa-md-10",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-simple-checkbox', {
    staticClass: "mr-n4",
    attrs: {
      "hide-details": "",
      "color": "primary"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("선택가능")])], 1)])], 1)], 1)], 1)], 1), _c('rental-action-spacetime-table', _vm._b({
    model: {
      value: _vm.schedules,
      callback: function ($$v) {
        _vm.schedules = $$v;
      },
      expression: "schedules"
    }
  }, 'rental-action-spacetime-table', {
    loading: _vm.loading,
    spaces: _vm.spaces,
    schedulesOfUser: _vm.schedulesOfUser,
    schedulesOnCloud: _vm.schedulesOnCloud,
    limitationMet: _vm.limitationMet,
    limitationMetOnBranches: _vm.limitationMetOnBranches
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--small"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("선택내용 확인")])]), _c('rental-action-schedules', _vm._b({}, 'rental-action-schedules', {
    spaces: _vm.spaces,
    schedules: _vm.schedules
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--small"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("사용목적")])]), _c('v-textarea', {
    staticClass: "rounded",
    attrs: {
      "outlined": "",
      "no-resize": "",
      "height": "180",
      "placeholder": "동아리명(해당시), 사용목적, 이용인원, 기자재사용내용(해당시) 입력해주세요."
    },
    model: {
      value: _vm.usage,
      callback: function ($$v) {
        _vm.usage = $$v;
      },
      expression: "usage"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--small"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("하남시도시재생지원센터 공유공간 이용규정 준수 서약서")])]), _c('terms-component', {
    attrs: {
      "code": "schedule-usage"
    }
  }), _c('div', {
    staticClass: "text-center mt-16 mt-md-26"
  }, [_c('v-checkbox', {
    staticClass: "v-checkbox--large d-inline-flex",
    attrs: {
      "value": "schedule-usage",
      "multiple": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('strong', {
          staticClass: "font-size-16 font-size-md-18 line-height-12 grey-1e--text"
        }, [_c('span', {
          staticClass: "font-size-20 font-size-md-24 primary--text"
        }, [_vm._v("하남시도시재생지원센터 공유공간 이용규정 준수 서약서")]), _vm._v(" 에 동의합니다")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.terms,
      callback: function ($$v) {
        _vm.terms = $$v;
      },
      expression: "terms"
    }
  })], 1)], 1), _c('v-container', [_c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "outlined": "",
      "block": "",
      "color": "grey-d6"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-240px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("예약신청")])], 1)], 1)], 1)]), _c('rental-limitation-per-week', _vm._b({
    model: {
      value: _vm.limitationPerWeekMet,
      callback: function ($$v) {
        _vm.limitationPerWeekMet = $$v;
      },
      expression: "limitationPerWeekMet"
    }
  }, 'rental-limitation-per-week', {
    date: _vm.date,
    limitations: _vm.limitations,
    schedules: _vm.schedules
  }, false)), _c('rental-limitation-per-week-on-branch', _vm._b({
    model: {
      value: _vm.limitationPerWeekMetOnBranches,
      callback: function ($$v) {
        _vm.limitationPerWeekMetOnBranches = $$v;
      },
      expression: "limitationPerWeekMetOnBranches"
    }
  }, 'rental-limitation-per-week-on-branch', {
    date: _vm.date,
    limitations: _vm.limitations,
    schedules: _vm.schedules
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }