<template>
    <v-card :loading="!program">
        <div class="pa-30 pa-md-40 px-lg-80 pt-lg-80 pb-lg-50">
            <v-row class="ma-n10 ma-md-n20 ma-lg-n30">
                <v-col cols="12" md="auto" order="1" order-md="2" class="pa-10 pa-md-20 pa-lg-30 line-height-0 text-center">
                    <v-card outlined class="d-inline-block">
                        <v-img width="344" :aspect-ratio="344 / 490" :src="program?.thumb?.src">
                            <template #placeholder>
                                <v-overlay absolute opacity="0.1">
                                    <v-icon x-large>mdi-image-off</v-icon>
                                </v-overlay>
                            </template>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="" order="2" order-md="1" class="pa-10 pa-md-20 pa-lg-30">
                    <div class="tit-wrap mb-20 mb-md-24">
                        <h3 class="tit tit--xs subtit">{{ program?.category?.name }}</h3>
                        <h2 class="tit">{{ program?.name }}</h2>
                    </div>
                    <v-divider class="mb-26 mb-md-32" />

                    <div class="page-text">
                        <v-row align="center" class="row--small">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">주제·내용</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.theme }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">접수기간</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.applicationPeriod }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">진행기간</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.period }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">수업요일</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.days }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">모집인원</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.capacityText }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">대상</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.target }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">참가비</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.isFree ? "무료" : program?.charge?.format?.() + "원" }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">접수방법</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.applicationMethod }}</v-col>
                        </v-row>
                        <v-row align="center" class="row--small mt-0">
                            <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">담당자</div></v-col>
                            <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                            <v-col>{{ program?.worker }}</v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <div v-show="!hideButton" class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="12" md="auto">
                        <program-application-btn v-bind="{ program }" class="w-100 w-md-340px" />
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-card>
</template>

<script>
import ProgramApplicationBtn from "./program-application-btn.vue";
export default {
    components: {
        ProgramApplicationBtn,
    },
    props: {
        program: { type: Object },
        hideButton: { type: Boolean, default: false },
    },
};
</script>

<style></style>
