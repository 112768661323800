var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "공간대여",
            "src": "/images/sub/sv-rental.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "공간대여",
            "tabActive": "예약안내"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("예약 절차")])]), _c('v-card', {
    attrs: {
      "color": "primary lighten-5",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-60"
  }, [_c('v-card', {
    attrs: {
      "rounded": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '' : 'pill',
      "color": "white",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 120 : 156,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center border",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle"
    }
  }, [_c('div', [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "60",
      "src": "/images/sub/rental/rental-intro-icon.png"
    }
  }), _c('div', {
    staticClass: "font-size-16 font-size-md-18 text-center mt-2 mb-n6"
  }, [_vm._v("STEP. 1")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-10 mb-md-12"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("예약 신청")])]), _c('div', {
    staticClass: "page-text"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("홈페이지를 통해 예약현황 확인")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("예약신청 후 온라인 예약서 작성 (이용 약관 확인 및 행사 목적 필수)")])], 1)], 1)])], 1)], 1)]), _c('div', {
    staticClass: "pl-md-80 my-14"
  }, [_c('v-img', {
    staticClass: "ma-auto ma-md-0",
    attrs: {
      "contain": "",
      "max-width": "18",
      "src": "/images/sub/rental/rental-intro-arrow.png"
    }
  })], 1), _c('v-card', {
    attrs: {
      "rounded": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '' : 'pill',
      "color": "white",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 120 : 156,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center border",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle"
    }
  }, [_c('div', [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "60",
      "src": "/images/sub/rental/rental-intro-icon2.png"
    }
  }), _c('div', {
    staticClass: "font-size-16 font-size-md-18 text-center mt-2 mb-n6"
  }, [_vm._v("STEP. 2")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-10 mb-md-12"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("예약 확인")])]), _c('div', {
    staticClass: "page-text"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("예약 담당자와 예약 내용 및 장비사용 확인")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("행사 개최 등 예약 적 여부 심사 및 대관 승인 (예약 신청일 기준 2.3일 소요)")])], 1)], 1)])], 1)], 1)]), _c('div', {
    staticClass: "pl-md-80 my-14"
  }, [_c('v-img', {
    staticClass: "ma-auto ma-md-0",
    attrs: {
      "contain": "",
      "max-width": "14",
      "src": "/images/sub/rental/rental-intro-arrow2.png"
    }
  })], 1), _c('v-card', {
    attrs: {
      "rounded": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '' : 'pill',
      "color": "white",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 120 : 156,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center border",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle"
    }
  }, [_c('div', [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "60",
      "src": "/images/sub/rental/rental-intro-icon3.png"
    }
  }), _c('div', {
    staticClass: "font-size-16 font-size-md-18 text-center mt-2 mb-n6"
  }, [_vm._v("STEP. 3")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-10 mb-md-12"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("대관 이용")])]), _c('div', {
    staticClass: "page-text"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("대관 승인 시 통보받은 도어락 비밀번호로 문 개방 및 이용 (다목적홀 제외)")])], 1)], 1)])], 1)], 1)]), _c('div', {
    staticClass: "pl-md-80 my-14"
  }, [_c('v-img', {
    staticClass: "ma-auto ma-md-0",
    attrs: {
      "contain": "",
      "max-width": "14",
      "src": "/images/sub/rental/rental-intro-arrow2.png"
    }
  })], 1), _c('v-card', {
    attrs: {
      "rounded": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '' : 'pill',
      "color": "white",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-md": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 120 : 156,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center border",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle"
    }
  }, [_c('div', [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "60",
      "src": "/images/sub/rental/rental-intro-icon4.png"
    }
  }), _c('div', {
    staticClass: "font-size-16 font-size-md-18 text-center mt-2 mb-n6"
  }, [_vm._v("STEP. 4")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-10 mb-md-12"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("대관 종료")])]), _c('div', {
    staticClass: "page-text"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("대관 종료 후 시설 담당자에게 고지")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("사용시설 등 확인")])], 1)], 1)])], 1)], 1)])], 1)])], 1), _c('page-section', {
    staticClass: "page-section--small page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("유의 사항")])]), _c('v-divider', {
    staticClass: "border-2 primary"
  }), _c('div', {
    staticClass: "pa-20 pa-md-30 page-text"
  }, [_c('v-row', {
    staticClass: "ma-n2 ma-md-n4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("이용시간은 개인, 단체별 주1회 최대 3시간 까지 가능합니다. (준비 및 철수 시간 포함)")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4 mt-2 mt-md-4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("사전 통보없이 노쇼(no-show)시 추후 공간 이용에 제한을 받을 수 있습니다.")])], 1), _c('v-row', {
    staticClass: "ma-n2 ma-md-n4 mt-2 mt-md-4"
  }, [_c('v-col', {
    staticClass: "pa-2 pa-md-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "pa-2 pa-md-4"
  }, [_vm._v("행사장 사전 세팅 필요 시, 담당자와 협의해주시기 바랍니다.")])], 1)], 1), _c('v-divider')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }