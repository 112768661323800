<template>
    <div class="sub-visual">
        <v-container>
            <h2 v-if="title" class="tit tit--xl white--text">{{ title }}</h2>
        </v-container>
        <div v-if="src" data-aos-once="true" data-aos="fade" class="sub-visual__img" :style="'background-image:url(' + src + ');'"></div>
        <slot v-if="this.$slots['subVisualImmersive']" name="subVisualImmersive" />
    </div>
</template>

<script>

export default {
    props: {
        title: { type: String, default: "" },
        src: { type: String, default: "" },
    },
    components: {
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
        },
    },
};
</script>

<style scoped>
.sub-visual{
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 200px;
}
.sub-visual__img {
    position: absolute;
    top:0;
    left:50%;
    z-index: -1;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    display: block;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 260px;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 360px;
    }
}
@media (min-width: 1200px) {
}
</style>
