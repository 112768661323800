<template>
    <v-row no-gutters>
        <!-- 목록 -->
        <v-col cols="2" v-for="(src, index) in itemsToShow" :key="index">
            <v-card flat tile outlined @click="showDialogWith(index)">
                <v-img v-bind="{ src, aspectRatio: 1 / 1 }" />
            </v-card>
        </v-col>
        <v-col v-if="items.length >= displaylength">
            <v-card flat tile outlined @click="showDialogWith(displaylength - 1)">
                <v-img :aspect-ratio="1 / 1" :src="items[this.displaylength - 1]">
                    <v-overlay absolute z-index="0">
                        <v-icon x-large>mdi-magnify-plus-outline</v-icon>
                    </v-overlay>
                </v-img>
            </v-card>
        </v-col>

        <!-- 오버레이 -->
        <v-dialog v-model="showsDialog" max-width="740" overlay-opacity=".7" >
            <v-fade-transition hide-on-leave>
                <v-btn v-show="showsCloseBtn" tile color="#1e1e1e" class="v-dialog__close" @click="showsDialog = false">
                    <v-icon color="white" size="30">mdi-close</v-icon>
                </v-btn>
            </v-fade-transition>

            <div :class="`rental-banner rental-banner-${random}`">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div v-for="(src, index) in items" :key="index" class="swiper-slide">
                            <v-card tile flat color="#1e1e1e" class="overflow-hidden">
                                <v-img v-bind="{ src, maxHeight: '60vh', aspectRatio: 1 / 1 }" contain>
                                    <template #placeholder>
                                        <v-overlay absolute z-index="0" opacity=".1">
                                            <v-icon>mdi-image-off</v-icon>
                                        </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </div>
                    </div>
                </div>
                <div class="swiper-control">
                    <div class="swiper-prev"><i class="icon icon-chevron-left-white"></i></div>
                    <div class="swiper-next"><i class="icon icon-chevron-right-white"></i></div>
                </div>
            </div>

            <div class="mt-20 mt-md-30">
                <div thumbsSlider :class="`rental-banner-thumb rental-banner-thumb-${random}`">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div v-for="(src, index) in items" :key="index" class="swiper-slide">
                                <v-card tile flat color="#1e1e1e" class="overflow-hidden">
                                    <v-img v-bind="{ src, maxHeight: '20vh', aspectRatio: 1 / 1 }" contain>
                                        <template #placeholder>
                                            <v-overlay absolute z-index="0" opacity=".1">
                                                <v-icon>mdi-image-off</v-icon>
                                            </v-overlay>
                                        </template>
                                    </v-img>
                                </v-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <v-slide-group v-model="dialogIndex" center-active @click:prev="dialogIndex -= 1" @click:next="dialogIndex += 1">
                <v-slide-item v-for="(src, index) in items" :key="index">
                    <v-card flat width="calc(100vmin - 52px - 52px)" class="px-6">
                        <v-card flat>
                            <v-img v-bind="{ src, maxHeight: '70vh', width: '100%' }" contain />
                        </v-card>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
            <v-slide-group v-model="dialogIndex" max-width="50vw" show-arrows center-active class="mt-10">
                <v-slide-item v-for="(src, index) in items" :key="index" v-slot="{ toggle }">
                    <v-card flat @click="toggle" width="calc((100vmin - 52px - 52px) / 6)" class="px-6">
                        <v-img v-bind="{ src, width: '100%', aspectRatio: 1 / 1 }" />
                    </v-card>
                </v-slide-item>
            </v-slide-group> -->
        </v-dialog>
    </v-row>
</template>

<script>
import Swiper from "swiper/bundle";

export default {
    props: {
        // items: { type: Array, default: () => [] },
        items: { type: Array, default: () => ["/images/test-img.png", "/images/test-img.png", "/images/test-img.png", "/images/test-img.png", "/images/test-img.png", "/images/test-img.png", "/images/test-img.png"] },
        displaylength: { type: Number, default: 6 },
    },
    data: () => ({
        random: Math.random()
            .toFixed(10)
            .slice(2, 12),
        swiper: undefined,
        dialogIndex: 0,
        showsDialog: false,
        showsCloseBtn: false,
    }),
    computed: {
        itemsToShow() {
            return this.items.slice(0, this.displaylength - 1);
        },
    },
    watch: {
        showsDialog() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        showDialogWith(index) {
            this.dialogIndex = index;
            this.showsDialog = true;
        },
        async init() {
            if (this.showsDialog) setTimeout(() => (this.showsCloseBtn = true), 400);
            else this.showsCloseBtn = false;

            if (this.showsDialog){
                this.$nextTick(() => {
                    var swiper = new Swiper(`.rental-banner-thumb-${this.random} .swiper-container`, {
                        observer: true,
                        observeParents: true,
                        freeMode: true,
                        watchSlidesProgress: true,
                        slidesPerView: 3,
                        spaceBetween: 8,
                        breakpoints: {
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                            },
                        },
                    });
                    var swiper2 = new Swiper(`.rental-banner-${this.random} .swiper-container`, {
                        observer: true,
                        observeParents: true,
                        spaceBetween: 10,
                        navigation: {
                            nextEl: `.rental-banner-${this.random} .swiper-next`,
                            prevEl: `.rental-banner-${this.random} .swiper-prev`,
                        },
                        thumbs: {
                            swiper: swiper,
                        },
                    });
                });
            }
        },
    },
};
</script>

<style scoped>
>>>.v-dialog{
    border-radius: 0;
    box-shadow: none;
}
.v-dialog__close.v-btn{
    position: fixed;
    top:0;
    right:0;
    width: 60px;
    height: 60px;
    padding: 0 0 14px 14px;
    border-radius: 0 0 0 100% !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-dialog__close.v-btn{
        width: 100px;
        height: 100px;
        padding: 0 0 20px 20px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

/* rental-banner */
>>>.rental-banner{
    position: relative;
}
@media (min-width:576px){
}
@media (min-width:768px){
    >>>.rental-banner,
    >>>.rental-banner-thumb{
        padding: 0 80px;
    }
    >>>.rental-banner .swiper-prev,
    >>>.rental-banner .swiper-next{
        background-color: rgba(0,0,0,.4);
    }
    >>>.rental-banner .swiper-prev{
        left:0;
    }
    >>>.rental-banner .swiper-next{
        right:0;
    }
}
@media (min-width:1024px){
    >>>.rental-banner,
    >>>.rental-banner-thumb{
        padding: 0 100px;
    }
    >>>.rental-banner .swiper-prev,
    >>>.rental-banner .swiper-next{
        width: 80px;
        height: 80px;
    }
}
@media (min-width:1200px){
}

/* rental-banner-thumb */
>>>.rental-banner-thumb .swiper-slide:not(.swiper-slide-thumb-active){
    cursor:pointer;
}
>>>.rental-banner-thumb .swiper-slide-thumb-active{
    position: relative;
}
>>>.rental-banner-thumb .swiper-slide-thumb-active::before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid var(--v-primary-base);
}
</style>
