<template></template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { branches, RENTAL_LIMITATION_TYPES, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        date: { type: String },
        schedules: { type: Array, default: () => [] },
        limitations: { type: Array, default: () => [] },
    },
    data: () => ({
        scheduleCountsOnCloud: [],
    }),
    computed: {
        limitation() {
            return this.limitations.find(({ type }) => type == RENTAL_LIMITATION_TYPES.PER_WEEK_ON_BRANCH.value) || null;
        },
        startDate() {
            if (this.limitation) {
                let { weekStartDay } = this.limitation;
                let startDate = dayjs(this.date).toISOString();
                while (dayjs(startDate).day() != weekStartDay) {
                    startDate = dayjs(startDate).subtract(1, "day").toISOString();
                }
                return startDate;
            } else return null;
        },
        endDate() {
            if (this.startDate) {
                return dayjs(this.startDate).add(6, "days").toISOString();
            } else return null;
        },
        scheduleCounts() {
            return this.schedules.reduce(
                (counts, { branch, date }) => {
                    let targetIndex = counts.findIndex((item) => item.branch == branch);

                    let isAfterStart = !dayjs(date).isBefore(this.startDate, "date");
                    let isBeforeEnd = !dayjs(date).isAfter(this.endDate, "date");
                    if (isAfterStart && isBeforeEnd) counts[targetIndex].count += 1;

                    return counts;
                },
                branches.map((branch) => ({ branch, count: 0 }))
            );
        },
        scheduleCountsTotal() {
            return branches.map((branch) => ({ branch, count: (this.scheduleCounts.find((item) => item.branch == branch)?.count || 0) + (this.scheduleCountsOnCloud.find((item) => item.branch == branch)?.count || 0) }));
        },
        limitationMetOnBranches() {
            if (this.limitation) {
                return this.scheduleCountsTotal.map((item) => ({ ...item, limitationMet: item.count >= this.limitation.hours }));
            } else return [];
        },
    },
    mounted() {
        this.getUserScheduleCount().then(this.emit);
    },
    watch: {
        date() {
            this.getUserScheduleCount();
        },
        limitation() {
            this.getUserScheduleCount();
        },
        limitationMetOnBranches() {
            this.emit();
        },
    },
    methods: {
        async getUserScheduleCount() {
            if (this.limitation && this.$store.state.payload?._user) {
                let state = [RENTAL_SCHEDULE_STATES.APPLIED.value, RENTAL_SCHEDULE_STATES.APPROVED.value];
                let dates = [this.startDate, this.endDate];
                this.scheduleCountsOnCloud = await Promise.all(branches.map(async (branch) => ({ branch, count: (await api.v1.me.rentalSchedules.gets({ params: { branch, state, dates } }))?.summary?.totalCount || 0 })));
            }
        },
        emit() {
            this.$emit("input", this.limitationMetOnBranches);
        },
    },
};
</script>

<style></style>
