<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="참여프로그램" src="/images/sub/sv-programs.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <program-navigation-row />
        </template>

        <v-container>
            <div class="tab-wrap">
                <program-navigation-chips />
            </div>

            <v-divider class="border-2 primary"/>
            <v-card v-for="program in programs" :key="program?._id" :to="`${$route.path}/${program?._id}`" tile elevation="0" class="border-bottom">
                <div class="px-20 py-30 px-md-30 px-lg-40">
                    <v-row align="center">
                        <v-col cols="12" md="auto" class="line-height-0 text-center">
                            <v-card outlined class="d-inline-block">
                                <v-img width="198" :aspect-ratio="198/280" :src="program?.thumb?.src">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1">
                                            <v-icon x-large>mdi-image-off</v-icon>
                                        </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="">
                            <div class="pl-lg-32">
                                <div>
                                    <v-row align="center" class="row--x-small">
                                        <v-col cols="auto">
                                            <h2 class="tit text-truncate">{{ program?.name }}</h2>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-chip v-if="program?.isFree" dark color="green lighten-1">무료</v-chip>
                                            <v-chip v-else dark color="yellow darken-3">유료</v-chip>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="mt-4">
                                    <v-row align="center" class="row--x-small">
                                        <v-col cols="auto" v-for="keyword in program?.keywords" :key="keyword?._id">
                                            <span class="font-size-16 font-size-md-18 font-size-lg-20 font-weight-semibold primary--text">#{{ keyword?.name }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="mt-16 mt-md-26 page-text">
                                    <v-row align="center" class="row--small">
                                        <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">운영일정</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ program?.period }}</v-col>
                                    </v-row>
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-md-74px font-weight-bold grey-1e--text">대 상</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ program?.target }}</v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn :width="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '100%' : $vuetify.breakpoint.md ? '100' : '120'" :height="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm ? '40' : $vuetify.breakpoint.md ? '100' : '120'" :color="program?.status == '접수중' ? 'primary' : 'grey'" :class="program?.status == '접수중' ? 'primary' : 'v-btn--disabled'">
                                {{ program?.status }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <div class="v-pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ProgramNavigationRow from "@/components/client/program/program-navigation-row.vue";
import ProgramNavigationChips from "@/components/client/program/program-navigation-chips.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        ProgramNavigationRow,
        ProgramNavigationChips,
    },
    data() {
        return {
            programs: [],

            limit: 10,
            summary: { totalCount: 0 },
        };
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            let { headers, params } = this;
            let { summary, programs } = await api.v1.programs.gets({ headers, params });
            this.summary = summary;
            this.programs = programs;
        },
    },
};
</script>
