<template>
    <spacetime-table-cell-disabled v-if="isClosed" v-bind="{ item, date, time }" :class="{ 'border-start': index != 0 }" />
    <spacetime-table-cell-checkbox v-else v-bind="{ item, date, time, datetime, schedules, schedulesOfUser, limitationMet }" v-on="{ add, del }" :class="{ 'border-start': index != 0 }" />
</template>

<script>
import dayjs from "dayjs";
import SpacetimeTableCellCheckbox from "./spacetime-table-cell-checkbox.vue";
import SpacetimeTableCellDisabled from "./spacetime-table-cell-disabled.vue";
const headers = [
    { width: 140, align: "center", text: "공간", value: "name", divider: true },
    { width: 140, align: "center", text: "수용인원", value: "capacity", divider: true },
    { width: 900, align: "center", text: "", value: "action", class: "pa-0", cellClass: "pa-0" },
];
const businessHours = [10, 11, 12, 13, 14, 15, 16, 17, 18].map((time) => ({ label: time + "시", time }));

export default {
    components: {
        SpacetimeTableCellCheckbox,
        SpacetimeTableCellDisabled,
    },
    props: {
        value: { type: Array, default: () => [] },

        item: { type: Object, default: () => ({}) },
        date: { type: String, default: Date.now().toDate() },
        time: { type: Number, default: 10 },

        index: { type: Number, default: 0 },

        schedulesOfUser: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],
        headers,
        businessHours,
    }),
    computed: {
        datetime() {
            return dayjs(this.date).set("hour", this.time).toISOString();
        },
        isClosed() {
            let hasTimePassed = dayjs().isAfter(this.datetime);
            if (hasTimePassed) return true;

            let hasScheduleOnCloud = this.item.schedulesOnCloud.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
            if (hasScheduleOnCloud) return true;

            return false;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        schedules() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.schedules = this.value;
        },
        emit() {
            this.$emit("input", this.schedules);
        },

        add(schedule) {
            this.schedules = [...this.schedules, schedule];
        },
        del(schedule) {
            let { _space, date, time } = schedule;
            let index = this.schedules.findIndex((item) => item._space == _space && item.date == date && item.time == time);
            if (index > -1) this.schedules.splice(index, 1);
        },
    },
};
</script>

<style scoped></style>
