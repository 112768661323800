var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-date-picker', _vm._b({
    attrs: {
      "no-title": "",
      "color": "primary"
    },
    on: {
      "update:picker-date": _vm.changeMonth
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }, 'v-date-picker', Object.assign({}, _vm.$attrs, {
    dayFormat: _vm.dayFormat,
    allowedDates: _vm.allowedDates
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }