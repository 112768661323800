<template>
    <v-tabs-rounded hide-slider show-arrows>
        <v-tab v-for="{ to, text } in items" :key="text" v-bind="{ to }" exact>
            <v-btn outlined rounded color="grey-d6">{{ text }}</v-btn>
        </v-tab>
    </v-tabs-rounded>
</template>

<script>
import VTabsRounded from "@/components/client/dumb/v-tabs-rounded.vue";

export default {
    components: {
        VTabsRounded,
    },
    props: {
        items: { type: Array, default: () => [{ to: "/", text: "Home" }] },
    },
};
</script>

<style scoped>
</style>