var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', _vm._b({}, 'v-col', _vm.$attrs, false), [_c('div', {
    staticClass: "min-h-46px min-h-md-60px d-flex align-center justify-center px-4 py-10 primary lighten-4"
  }, [_c('v-simple-checkbox', _vm._b({
    attrs: {
      "value": _vm.isChecked,
      "hide-details": "",
      "color": "primary"
    },
    on: {
      "click": _vm.toggleSchedule
    }
  }, 'v-simple-checkbox', {
    disabled: _vm.disabled
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }