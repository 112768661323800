var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isClosed ? _c('spacetime-table-cell-disabled', _vm._b({
    class: {
      'border-start': _vm.index != 0
    }
  }, 'spacetime-table-cell-disabled', {
    item: _vm.item,
    date: _vm.date,
    time: _vm.time
  }, false)) : _c('spacetime-table-cell-checkbox', _vm._g(_vm._b({
    class: {
      'border-start': _vm.index != 0
    }
  }, 'spacetime-table-cell-checkbox', {
    item: _vm.item,
    date: _vm.date,
    time: _vm.time,
    datetime: _vm.datetime,
    schedules: _vm.schedules,
    schedulesOfUser: _vm.schedulesOfUser,
    limitationMet: _vm.limitationMet
  }, false), {
    add: _vm.add,
    del: _vm.del
  }));

}
var staticRenderFns = []

export { render, staticRenderFns }