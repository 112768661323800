var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "참여프로그램",
            "src": "/images/sub/sv-programs.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('program-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tab-wrap"
  }, [_c('program-navigation-chips')], 1), _c('v-divider', {
    staticClass: "border-2 primary"
  }), _vm._l(_vm.programs, function (program) {
    var _program$thumb;
    return _c('v-card', {
      key: program === null || program === void 0 ? void 0 : program._id,
      staticClass: "border-bottom",
      attrs: {
        "to": `${_vm.$route.path}/${program === null || program === void 0 ? void 0 : program._id}`,
        "tile": "",
        "elevation": "0"
      }
    }, [_c('div', {
      staticClass: "px-20 py-30 px-md-30 px-lg-40"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "line-height-0 text-center",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-card', {
      staticClass: "d-inline-block",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "width": "198",
        "aspect-ratio": 198 / 280,
        "src": program === null || program === void 0 ? void 0 : (_program$thumb = program.thumb) === null || _program$thumb === void 0 ? void 0 : _program$thumb.src
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', {
            attrs: {
              "x-large": ""
            }
          }, [_vm._v("mdi-image-off")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('div', {
      staticClass: "pl-lg-32"
    }, [_c('div', [_c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h2', {
      staticClass: "tit text-truncate"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.name))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [program !== null && program !== void 0 && program.isFree ? _c('v-chip', {
      attrs: {
        "dark": "",
        "color": "green lighten-1"
      }
    }, [_vm._v("무료")]) : _c('v-chip', {
      attrs: {
        "dark": "",
        "color": "yellow darken-3"
      }
    }, [_vm._v("유료")])], 1)], 1)], 1), _c('div', {
      staticClass: "mt-4"
    }, [_c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, _vm._l(program === null || program === void 0 ? void 0 : program.keywords, function (keyword) {
      return _c('v-col', {
        key: keyword === null || keyword === void 0 ? void 0 : keyword._id,
        attrs: {
          "cols": "auto"
        }
      }, [_c('span', {
        staticClass: "font-size-16 font-size-md-18 font-size-lg-20 font-weight-semibold primary--text"
      }, [_vm._v("#" + _vm._s(keyword === null || keyword === void 0 ? void 0 : keyword.name))])]);
    }), 1)], 1), _c('div', {
      staticClass: "mt-16 mt-md-26 page-text"
    }, [_c('v-row', {
      staticClass: "row--small",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-md-74px font-weight-bold grey-1e--text"
    }, [_vm._v("운영일정")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.period))])], 1), _c('v-row', {
      staticClass: "row--small mt-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "w-md-74px font-weight-bold grey-1e--text"
    }, [_vm._v("대 상")])]), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      staticClass: "h-10px",
      attrs: {
        "vertical": ""
      }
    })], 1), _c('v-col', [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.target))])], 1)], 1)])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-btn', {
      class: (program === null || program === void 0 ? void 0 : program.status) == '접수중' ? 'primary' : 'v-btn--disabled',
      attrs: {
        "width": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '100%' : _vm.$vuetify.breakpoint.md ? '100' : '120',
        "height": _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '40' : _vm.$vuetify.breakpoint.md ? '100' : '120',
        "color": (program === null || program === void 0 ? void 0 : program.status) == '접수중' ? 'primary' : 'grey'
      }
    }, [_vm._v(" " + _vm._s(program === null || program === void 0 ? void 0 : program.status) + " ")])], 1)], 1)], 1)]);
  }), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mx-4",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }