<template>
    <v-data-table v-bind="{ loading, items, headers }" mobile-breakpoint="0" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" class="v-data-table--default v-data-table--default-mo">
        <template #[`header.action`]>
            <div class="pa-12">이용시간</div>
            <v-divider />
            <v-row no-gutters>
                <v-col v-for="({ label }, index) in businessHours" :key="label" :class="{ 'border-start': index != 0 }">
                    <div class="px-4 py-6">{{ label }}</div>
                </v-col>
            </v-row>
        </template>
        <template #no-data>대관 가능한 공간이 없습니다</template>
        <template #[`item.action`]="{ item }">
            <v-row no-gutters>
                <template v-if="item.isOnHoliday">
                    <v-col class="pa-16">운영하지 않는 날짜입니다</v-col>
                </template>
                <template v-else-if="item.isOnBusiness">
                    <template v-for="({ time }, index) in businessHours">
                        <spacetime-table-cell v-model="schedules" :key="`${item.name}-${time}`" v-bind="{ item, date, time, index, schedulesOfUser, limitationMet }" />
                    </template>
                </template>
                <template v-else>
                    <v-col class="pa-16">운영하지 않는 요일입니다</v-col>
                </template>
            </v-row>
        </template>
        <template #footer>
            <div class="d-xl-none mt-6 mt-md-10">
                <v-img contain height="32" src="/images/common/scroll.png" />
            </div>
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import SpacetimeTableCell from "./spacetime-table-cell.vue";
const headers = [
    { width: 140, align: "center", text: "공간", value: "name", divider: true },
    { width: 140, align: "center", text: "수용인원", value: "capacity", divider: true },
    { width: 900, align: "center", text: "", value: "action", class: "pa-0", cellClass: "pa-0" },
];
const businessHours = [10, 11, 12, 13, 14, 15, 16, 17, 18].map((time) => ({ label: time + "시", time }));

export default {
    components: {
        SpacetimeTableCell,
    },
    props: {
        loading: { type: Boolean, default: false },

        value: { type: Array, default: () => [] },
        spaces: { type: Array, default: () => [] },
        schedulesOfUser: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },
        limitationMetOnBranches: { type: Array, default: () => [] },
    },
    data: () => ({
        schedules: [],
        headers,
        businessHours,
    }),
    computed: {
        date() {
            return this.$route.query.date || Date.now().toDate();
        },
        branch() {
            return this.$route.query.branch;
        },
        dayOfDate() {
            return dayjs(this.date).day();
        },
        items() {
            if (this.loading) return [];

            return this.spaces
                .filter((space) => (this.branch ? space.branch == this.branch : true))
                .map((space) => ({
                    ...space,
                    schedulesOnCloud: this.schedulesOnCloud.filter(({ _space }) => _space == space._id),
                    limitationMetOnBranch: this.limitationMetOnBranches.find(({ branch }) => branch == space.branch)?.limitationMet || false,
                    isOnBusiness: (space?.businessDays || []).includes(this.dayOfDate),
                    isOnHoliday: (space?.holidays || []).some((date) => dayjs(this.date).isSame(date, "date")),
                }));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        schedules() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.schedules = this.value;
        },
        emit() {
            this.$emit("input", this.schedules);
        },
    },
};
</script>

<style scoped></style>
