<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "../dumb/navigation-row.vue";
let items = [
    { text: "전체일정", to: "/program/schedule" },
    { text: "전체프로그램", to: "/program/items" },
];
export default {
    components: { NavigationRow },
    data: () => ({ items }),
};
</script>

<style></style>
