var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-tab-wrap"
  }, [_c('v-container', [_c('ul', {
    staticClass: "tab justify-center"
  }, _vm._l(_vm.items, function (_ref) {
    var to = _ref.to,
      alternate = _ref.alternate,
      text = _ref.text;
    return _c('li', {
      key: text,
      staticClass: "tab__li",
      class: {
        active: _vm.$route.path.includes(to) || _vm.$route.path.includes(alternate)
      }
    }, [_c('router-link', {
      staticClass: "tab__btn",
      attrs: {
        "to": to
      }
    }, [_c('span', [_vm._v(_vm._s(text))])])], 1);
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }