var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "참여프로그램",
            "src": "/images/sub/sv-programs.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('program-navigation-row')];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-20 pa-md-30"
  }, [_c('program-schedule-calendar', _vm._b({
    attrs: {
      "no-title": ""
    }
  }, 'program-schedule-calendar', {
    programs: _vm.programs
  }, false))], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('program-schedule-list', _vm._b({}, 'program-schedule-list', {
    programs: _vm.programs
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }