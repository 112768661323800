<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="참여프로그램" src="/images/sub/sv-programs.jpg"></sub-visual>
        </template>

        <v-container>
            <program-view-card v-bind="{ program }" />
            <v-divider class="border-2 primary mt-40 mt-md-60 mt-lg-80" />
            <div class="pa-20 pa-md-30 pa-lg-40">
                <div class="page-text" v-html="program?.detail" />
            </div>
            <v-divider />

            <div class="mt-12 mt-md-22">
                <v-row justify="end" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn outlined color="grey-d6" class="w-140px" @click="goList">목록</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ProgramViewCard from "@/components/client/program/program-view-card.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        ProgramViewCard,
    },
    props: {
        _program: { type: String, default: null },
    },
    data() {
        return {
            program: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { program } = await api.v1.programs.get({ _id: this._program });
            this.program = program;
        },
        goList() {
            this.$router.go(-1);
        },
    },
};
</script>
