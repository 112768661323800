<template>
    <navigation-chips v-bind="{ items }" />
</template>

<script>
import NavigationChips from "../dumb/navigation-chips.vue";
export default {
    components: {
        NavigationChips,
    },
    computed: {
        items() {
            let items = [
                { text: "전체", to: { query: {} } },
                { text: "접수중", to: { query: { status: "접수중" } } },
                { text: "진행중", to: { query: { status: "진행중" } } },
                { text: "종료", to: { query: { status: "종료" } } },
            ];
            let page = this.$route.query.page;
            if (page) items = items.map((item) => ({ ...item, to: { ...item.to, query: { ...item.to.query, page } } }));
            return items;
        },
    },
};
</script>

<style></style>
