var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual"
  }, [_c('v-container', [_vm.title ? _c('h2', {
    staticClass: "tit tit--xl white--text"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e()]), _vm.src ? _c('div', {
    staticClass: "sub-visual__img",
    style: 'background-image:url(' + _vm.src + ');',
    attrs: {
      "data-aos-once": "true",
      "data-aos": "fade"
    }
  }) : _vm._e(), this.$slots['subVisualImmersive'] ? _vm._t("subVisualImmersive") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }