<template>
    <v-data-table v-bind="{ items, headers }" disable-filtering disable-pagination disable-sort hide-default-footer class="v-data-table--default">
        <template #no-data> 선택한 스케줄이 없습니다 </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
let headers = [
    { width: "30%", align: "center", text: "지점", value: "branch" },
    { width: "30%", align: "center", text: "공간명", value: "name" },
    { width: "30%", align: "center", text: "사용시간", value: "datetime" },
];
export default {
    props: {
        spaces: { type: Array, default: () => [] },
        schedules: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return this.schedules.map((schedule) => {
                let { branch, name } = this.spaces.find(({ _id }) => _id == schedule._space) || {};
                let datetime = dayjs(schedule.date)
                    .set("hour", schedule.time)
                    .format("YY년 M월 D일 HH시");
                return { ...schedule, branch, name, datetime };
            });
        },
    },
};
</script>
