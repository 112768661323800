var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program2, _vm$program2$category, _vm$program3, _vm$program4, _vm$program5, _vm$program6, _vm$program7, _vm$program8, _vm$program9, _vm$program10, _vm$program11, _vm$program11$charge, _vm$program11$charge$, _vm$program12, _vm$program13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "loading": !_vm.program
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 px-lg-80 pt-lg-80 pb-lg-50"
  }, [_c('v-row', {
    staticClass: "ma-n10 ma-md-n20 ma-lg-n30"
  }, [_c('v-col', {
    staticClass: "pa-10 pa-md-20 pa-lg-30 line-height-0 text-center",
    attrs: {
      "cols": "12",
      "md": "auto",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('v-card', {
    staticClass: "d-inline-block",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "width": "344",
      "aspect-ratio": 344 / 490,
      "src": (_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.src
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-off")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-10 pa-md-20 pa-lg-30",
    attrs: {
      "cols": "12",
      "md": "",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-24"
  }, [_c('h3', {
    staticClass: "tit tit--xs subtit"
  }, [_vm._v(_vm._s((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : (_vm$program2$category = _vm$program2.category) === null || _vm$program2$category === void 0 ? void 0 : _vm$program2$category.name))]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v(_vm._s((_vm$program3 = _vm.program) === null || _vm$program3 === void 0 ? void 0 : _vm$program3.name))])]), _c('v-divider', {
    staticClass: "mb-26 mb-md-32"
  }), _c('div', {
    staticClass: "page-text"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("주제·내용")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program4 = _vm.program) === null || _vm$program4 === void 0 ? void 0 : _vm$program4.theme))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("접수기간")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program5 = _vm.program) === null || _vm$program5 === void 0 ? void 0 : _vm$program5.applicationPeriod))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("진행기간")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program6 = _vm.program) === null || _vm$program6 === void 0 ? void 0 : _vm$program6.period))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("수업요일")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program7 = _vm.program) === null || _vm$program7 === void 0 ? void 0 : _vm$program7.days))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("모집인원")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program8 = _vm.program) === null || _vm$program8 === void 0 ? void 0 : _vm$program8.capacityText))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("대상")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program9 = _vm.program) === null || _vm$program9 === void 0 ? void 0 : _vm$program9.target))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("참가비")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program10 = _vm.program) !== null && _vm$program10 !== void 0 && _vm$program10.isFree ? "무료" : ((_vm$program11 = _vm.program) === null || _vm$program11 === void 0 ? void 0 : (_vm$program11$charge = _vm$program11.charge) === null || _vm$program11$charge === void 0 ? void 0 : (_vm$program11$charge$ = _vm$program11$charge.format) === null || _vm$program11$charge$ === void 0 ? void 0 : _vm$program11$charge$.call(_vm$program11$charge)) + "원"))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("접수방법")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program12 = _vm.program) === null || _vm$program12 === void 0 ? void 0 : _vm$program12.applicationMethod))])], 1), _c('v-row', {
    staticClass: "row--small mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-74px font-weight-bold grey-1e--text"
  }, [_vm._v("담당자")])]), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_vm._v(_vm._s((_vm$program13 = _vm.program) === null || _vm$program13 === void 0 ? void 0 : _vm$program13.worker))])], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideButton,
      expression: "!hideButton"
    }],
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('program-application-btn', _vm._b({
    staticClass: "w-100 w-md-340px"
  }, 'program-application-btn', {
    program: _vm.program
  }, false))], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }