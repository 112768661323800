<template>
    <v-card v-bind="{ loading }" class="overflow-hidden">
        <v-row no-gutters>
            <v-col cols="12" md="7">
                <div class="pa-20 pa-md-30 px-lg-60 py-lg-40">
                    <rental-schedule-date-picker v-model="date" class="v-date-picker--default" />
                </div>
            </v-col>
            <v-col cols="12" md="5">
                <div class="d-flex align-center h-100 primary lighten-5 pa-20 pa-md-30 px-lg-50 py-lg-60">
                    <div class="w-100">
                        <div>
                            <div class="mb-8 mb-md-10">
                                <h3 class="font-size-16 font-size-md-18">신청순서</h3>
                            </div>
                            <v-card outlined style="background-color: transparent">
                                <div class="pa-12 pa-md-18">
                                    <v-row no-gutters align="center" justify="center" justify-xl="start" class="page-text page-text--sm line-height-13">
                                        <v-col cols="auto">날짜, 시간, <br class="d-none d-xl-block" />공간 선택</v-col>
                                        <v-col cols="auto"><v-icon color="grey lighten-1">mdi-chevron-right</v-icon></v-col>
                                        <v-col cols="auto">행사정보 <br class="d-none d-xl-block" />담당자 정보 입력</v-col>
                                        <v-col cols="auto"><v-icon color="grey lighten-1">mdi-chevron-right</v-icon></v-col>
                                        <v-col cols="auto">대관 <br class="d-none d-xl-block" />신청하기</v-col>
                                        <v-col cols="auto"><v-icon color="grey lighten-1">mdi-chevron-right</v-icon></v-col>
                                        <v-col cols="auto">관리자 승인 <br class="d-none d-xl-block" />후 이용</v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </div>

                        <div class="mt-20 mt-md-34">
                            <div class="mb-8 mb-md-10">
                                <h3 class="font-size-16 font-size-md-18">유의사항</h3>
                            </div>
                            <div class="page-text page-text--sm">
                                <v-row class="ma-n2 ma-md-n4">
                                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                    <v-col class="pa-2 pa-md-4">최소 이용 예정 시각 3시간 전까지 예약신청이 가능합니다. </v-col>
                                </v-row>
                                <v-row class="ma-n2 ma-md-n4">
                                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                    <v-col class="pa-2 pa-md-4">대관 신청은 이용자의 독점을 방지하고자 주 1회 3시간으로 제한하고 있습니다.</v-col>
                                </v-row>
                                <v-row class="ma-n2 ma-md-n4">
                                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                    <v-col class="pa-2 pa-md-4">하다센터 정기 대관 및 기타 자세한 문의는 카카오톡 생활문화센터 하다 채널 또는 담당자 (031-791-5002)을 통해 연락 바랍니다.</v-col>
                                </v-row>
                                <v-row class="ma-n2 ma-md-n4">
                                    <v-col cols="auto" class="pa-2 pa-md-4">-</v-col>
                                    <v-col class="pa-2 pa-md-4">날다센터 멀티룸의 이용 시간은 6시까지이며 대관 예약 등 문의 사항은 (031-796-2173)을 통해 연락 바랍니다.</v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import dayjs from "dayjs";
import RentalScheduleDatePicker from "@/components/dumb/rental-schedule-date-picker.vue";
export default {
    components: {
        RentalScheduleDatePicker,
    },
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        date: Date.now().toDate(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.date"() {
            this.sync();
        },
        date() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.date = this.$route.query.date || Date.now().toDate();
        },

        emit() {
            let { date } = this;
            let query = { ...this.$route.query, date };
            if (date == dayjs().format("YYYY-MM-DD")) delete query.date;
            this.$router.replace({ query });
        },
    },
};
</script>

<style></style>
