<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="공간대여" src="/images/sub/sv-rental.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <rental-navigation-row />
        </template>

        <v-container>
            <div class="tab-wrap">
                <rental-space-navigation />
            </div>

            <v-card v-for="(item, index) in spaces" :key="item?._id" :class="index != 0 ? 'mt-40 mt-md-50 mt-lg-60' : ''">
                <div class="pa-30 pa-md-40 pa-lg-60">
                    <v-row>
                        <v-col cols="12" md="auto">
                            <v-img :width="$vuetify.breakpoint.xs ? '100%' : 350" :aspect-ratio="1 / 1" :src="item?.thumb?.url" class="rounded-lg ma-auto">
                                <template #placeholder>
                                    <v-overlay absolute z-index="0" opacity=".1"> <v-icon>mdi-image</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </v-col>
                        <v-col>
                            <div class="mt-20 mt-md-30 ml-md-20 ml-lg-40 ml-xl-72">
                                <div class="tit-wrap mb-0">
                                    <h3 class="tit tit--xs subtit">{{ item?.branch }}</h3>
                                    <h2 class="tit">{{ item?.name }}</h2>
                                </div>
                                <v-divider class="my-14 my-md-24" />

                                <div class="page-text">
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-lg-100px font-weight-bold grey-1e--text">면적(㎡)</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ item?.area?.square?.format?.() }}</v-col>
                                    </v-row>
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-lg-100px font-weight-bold grey-1e--text">넓이(m)</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ item?.area?.width }} x {{ item?.area?.height }}</v-col>
                                    </v-row>
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-lg-100px font-weight-bold grey-1e--text">수용규모(명)</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ item?.capacity }}</v-col>
                                    </v-row>
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-lg-100px font-weight-bold grey-1e--text">대여용도</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col>{{ item?.usage }}</v-col>
                                    </v-row>
                                    <v-row align="center" class="row--small mt-0">
                                        <v-col cols="auto"><div class="w-lg-100px font-weight-bold grey-1e--text">설비</div></v-col>
                                        <v-col cols="auto" class="d-flex align-center"><v-divider vertical class="h-10px"/></v-col>
                                        <v-col style="white-space:pre-line">{{ item?.facility }}</v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="mt-30 mt-md-40 mt-lg-60">
                        <imgs-overlay-list :items="(item?.photos || []).map(({ url }) => url)" />
                    </div>
                </div>
            </v-card>

            <div class="v-pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" class="mt-4 mx-4" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ImgsOverlayList from "@/components/client/dumb/imgs-overlay-list.vue";
import RentalNavigationRow from "@/components/client/rental/rental-navigation-row.vue";
import RentalSpaceNavigation from "@/components/client/rental/rental-space-navigation.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        RentalNavigationRow,
        RentalSpaceNavigation,
        ImgsOverlayList,
    },
    data: () => ({
        spaces: [],

        limit: 10,
        summary: { totalCount: 0 },
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        headers() {
            let { limit } = this;
            return { skip: (this.page - 1) * limit, limit };
        },
        params() {
            let { query } = this.$route;
            return { ...query, shows: true };
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            const path = "/rental/spaces";
            if (this.$route.path !== path) this.$router.replace({ path });
        },
        async search() {
            let { headers, params } = this;
            let { summary, spaces } = await api.v1.rentalSpaces.gets({ headers, params });
            this.summary = summary;
            this.spaces = spaces;
        },
    },
};
</script>
