<template>
    <v-col v-bind="$attrs">
        <div class="min-h-46px min-h-md-60px d-flex align-center justify-center px-4 py-10 primary lighten-4">
            <v-simple-checkbox :value="isChecked" v-bind="{ disabled }" @click="toggleSchedule" hide-details color="primary"></v-simple-checkbox>
        </div>
    </v-col>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        item: { type: Object, default: () => ({}) },
        date: { type: String },
        time: { type: Number },
        datetime: { type: String },

        schedules: { type: Array, default: () => [] },
        schedulesOfUser: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },
    },
    computed: {
        schedule() {
            let { date, time, datetime } = this;
            let { _id: _space, branch } = this.item;
            return { _space, branch, date, time, datetime };
        },

        isChecked() {
            let { _space, datetime } = this.schedule;
            return this.schedules.some((item) => item._space == _space && item.datetime == datetime);
        },

        hasSchedule() {
            return this.schedules.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
        },
        hasUserSchedule() {
            return this.schedulesOfUser.some(({ datetime }) => dayjs(datetime).isSame(this.datetime, "hour"));
        },
        limitationMetOnBranch() {
            return this.item?.limitationMetOnBranch;
        },
        disabled() {
            return !this.isChecked && (this.limitationMet || this.limitationMetOnBranch || this.hasSchedule || this.hasUserSchedule);
        },
    },
    methods: {
        toggleSchedule() {
            if (this.isChecked) this.$emit("del", this.schedule);
            else if (this.limitationMet) alert("한 주 동안 3시간을 초과해 대여할 수 없습니다");
            else if (this.limitationMetOnBranch) alert("한 주 동안 지점당 3시간을 초과해 대여할 수 없습니다");
            else if (this.hasSchedule) alert("이미 동일한 일시에 스케줄을 추가하셨습니다");
            else if (this.hasUserSchedule) alert("이미 동일한 일시에 스케줄을 예약하셨습니다");
            else this.$emit("add", this.schedule);
        },
    },
};
</script>

<style scoped></style>
